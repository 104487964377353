import { ArrowDirectionWrapper } from "@Components/LandingPage/HeroArea/HeroArea.styled";
import { APPLE_APPSTORE_LINK, GOOGLE_PLAY_LINK } from "@Constants/AppStoreLinks";
import { useAuth } from "@Context/Auth.context";
import NavDropdownProvider from "@Context/NavDropdown.context";
import { getLocalizedHomeSubRoute } from "@Utils/Strings";
import Logo from "LocaleBased/Logo";

import OrcasBanner from "@Components/OrcasBanner";
import { FOUNDING_DAY_THEME, KSA_COUNTRY_CODE } from "@Constants/index";
import { useThemeType } from "@Context/ThemeType.context";
import { AppsIconBgColor } from "LocaleBased";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import NavArrowRightSvgIcon from "public/svgs/icons/global-icons/LongArrowRightSvgIcon";
import AppStoreSvgImg from "public/svgs/images/social-apps/AppStoreSvgImg";
import GooglePlaySvgImg from "public/svgs/images/social-apps/GooglePlaySvgImg";
import BaimsLogoSvg from "public/svgs/logos/BaimsLogoSvg";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClickAway, useMedia } from "react-use";
import { Dispatch as ReduxDispatch, RootState } from "store";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { APIResponse, Meta } from "types/ApiResponse";
import { BreadCrumbs } from "types/BreadCrumbs";
import { NavbarVariant } from "types/common";
import { Subject } from "types/subject";
import {
  AppsWrapper,
  BlurredBg,
  BurgerMenu,
  DownloadAppLink,
  Header,
  HeaderInner,
  LoginButton,
  LogoLink,
  NavLi,
  NavLink,
  NavUl,
  ProfileBtn,
  ResMenuContainer,
  ResMenuLi,
  ResMenuLink,
  ResMenuProfile,
  ResMenuTitle,
  ResMenuUl,
  ResMenuWrapper,
  Separator,
  Spinner,
  SpinnerWrapper,
} from "./Navbar.styled";
import ExploreDropdown from "./components/ExploreDropdown";
import NavbarBanner from "./components/NavbarBanner";
import NavbarLangSwitch from "./components/NavbarLangSwitch";
import NavbarSearch from "./components/NavbarSearch";
import ProfileButton from "./components/ProfileButton";
import { CountDownBar } from "@Components/CountDownBar";

const LoginBox = dynamic(() => import("@Components/SubjectPage/components/Popups/LoginBox"), { ssr: false });

interface Props {
  navbarType: NavbarVariant;
  navBorder?: boolean;
  subjectData?: Subject;
  noOpacity?: boolean;
  isLanding?: boolean;
  breadCrumbHirarchy?: APIResponse<BreadCrumbs[], Meta>;
}

declare global {
  interface Window {
    fullpage_api: any;
  }
}

export default function Navbar({ navbarType, navBorder, isLanding, breadCrumbHirarchy }: Props) {
  const { t } = useTranslation("common");
  const { t: tProfile } = useTranslation("Profile");
  const { route, asPath } = useRouter();
  const { isUserFetching, userData, logOut, setLoginModal, userToken } = useAuth();
  const [showBlurredBg, setShowBlurredBg] = useState(false);
  const [resMenu, setResMenu] = useState(false);
  const [imgSrc, setImgSrc] = useState<null | string>(null);
  const [logoNavigate, setLogoNavigate] = useState(null);
  const [isOrcasBanner, setIsOrcasBanner] = useState(true);
  const isDesktop = useMedia("(min-width: 992px)");
  const isMobile = useMedia("(max-width: 576px)");
  const resMenuRef = useRef();
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  const [isFoundingDay, setIsFoundingDay] = useState<boolean>(
    Boolean(localIpInfo?.country_code === KSA_COUNTRY_CODE && themeType === FOUNDING_DAY_THEME)
  );
  const inHomeKSA = asPath.includes("/home/sa");
  const { isBannerVisible, endDate } = useSelector((state: RootState) => state.banner);
  const dispatch = useDispatch<ReduxDispatch>();

  useClickAway(resMenuRef, () => {
    setResMenu(false);
    // if (window && window.fullpage_api) {
    //   window.fullpage_api.setAllowScrolling(true);
    // }
  });

  useEffect(() => {
    setShowBlurredBg(resMenu);
    if (window && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(!resMenu);
    }
  }, [resMenu]);

  useEffect(() => {
    if (userData) {
      setImgSrc(userData.profile_picture.url);
    }
  }, [isUserFetching]);

  useEffect(() => {
    if (userToken) {
      setLogoNavigate(`/home/${getLocalizedHomeSubRoute()}`);
    } else {
      setLogoNavigate("/");
    }
  }, []);

  const onCountDownComplete = () => {
    dispatch.banner._setIsBannerVisible(false);
  };

  return (
    <NavDropdownProvider>
      <BlurredBg isActive={showBlurredBg} />
      <LoginBox />
      <Header
        navBorder={navBorder}
        variant={navbarType}
        isResMenuOpen={resMenu}
        countryCode={localIpInfo?.country_code}
        className={isLanding && "is-landing-nav"}
        isBannerVisible={isBannerVisible}
        themeType={themeType}
        isLandingPage={asPath}
        isOrcasBanner={isOrcasBanner}
      >
        {/* {isOrcasBanner && <OrcasBanner />} */}
        <CountDownBar
          targetDate={new Date("2025-02-28T23:59:59")}
          placeholder={<OrcasBanner />}
          isKSAUser={localIpInfo?.country_code?.toLowerCase() === "sa"}
        />

        {/* <CountDownBar targetDate={targetDate} placeholder={() => <OrcasBanner />} /> */}
        {isBannerVisible && !resMenu && <NavbarBanner endDate={endDate} onCountDownComplete={onCountDownComplete} />}
        <Container>
          <Row>
            {navbarType === "basic" ? (
              <LogoLink href={logoNavigate}>
                <BaimsLogoSvg color="#000000" />
              </LogoLink>
            ) : (
              <Col col={12}>
                <HeaderInner>
                  <LogoLink href={logoNavigate}>
                    <Logo breadCrumbHirarchy={breadCrumbHirarchy} navbarType={navbarType} />
                  </LogoLink>
                  {/* NOTE: THIS IS COMMENT UNTILL WE CREATE THIS PAGES */}
                  {/* <Nav>
                      <NavUl>
                        <NavLi>
                          <NavLink href="/about">{t("about")}</NavLink>
                        </NavLi>
                        <NavLi>
                          <NavLink href="/explore">{t("explore")}</NavLink>
                        </NavLi>
                      </NavUl>
                    </Nav> */}
                  {["/", "/home/[[...slug]]"].includes(route) && (
                    <NavUl>
                      <NavLi>
                        <ExploreDropdown setShowBlurredBg={setShowBlurredBg} />
                      </NavLi>
                      {inHomeKSA && (
                        <NavLi>
                          <NavLink as="a" href="https://safeer.baims.com/" rel="noopener noreferrer" target="_blank">
                            {t("become-ambassador")}
                          </NavLink>
                        </NavLi>
                      )}
                      {isLanding && (
                        <NavLi>
                          <NavLink href={process.env.NEXT_PUBLIC_APP_AI_URL}>{t("baims-ai")}</NavLink>
                        </NavLi>
                      )}
                    </NavUl>
                  )}
                  {!isMobile && (
                    <NavbarSearch setShowBlurredBg={setShowBlurredBg} navbarType={navbarType} setResMenu={setResMenu} />
                  )}
                  <NavbarLangSwitch setShowBlurredBg={setShowBlurredBg} navbarType={navbarType} />
                  <Separator />
                  {isDesktop ? (
                    isUserFetching ? (
                      <SpinnerWrapper>
                        <Spinner size={20} />
                      </SpinnerWrapper>
                    ) : Boolean(userData) ? (
                      <ProfileButton setShowBlurredBg={setShowBlurredBg} />
                    ) : (
                      <LoginButton
                        variant={navbarType === "white-bg" ? "default" : "white-filled"}
                        onClick={() => setLoginModal(true)}
                      >
                        {t("login")}
                      </LoginButton>
                    )
                  ) : (
                    <ResMenuContainer ref={resMenuRef} isOpened={resMenu && isMobile}>
                      <BurgerMenu
                        onClick={() => setResMenu((prev) => !prev)}
                        isOpened={resMenu && isMobile}
                        className={resMenu && "cross"}
                      />
                      <ResMenuWrapper isOpen={resMenu}>
                        {/* TODO: DELETED LATER NOT IN FIGMA */}
                        {/* <LogoLink href={`https://${locate}baims.com/`}>
                          <Logo src={`/images/logos/logo-${locale}.svg`} alt={t("baims")} />
                        </LogoLink> */}
                        {isMobile && (
                          <NavbarSearch
                            setShowBlurredBg={setShowBlurredBg}
                            navbarType={navbarType}
                            setResMenu={setResMenu}
                          />
                        )}
                        {Boolean(userData) ? (
                          <Fragment>
                            <ResMenuTitle>{t("main-menu")}</ResMenuTitle>
                            <ResMenuUl>
                              {/* NOTE: THIS IS COMMENT UNTILL WE CREATE THIS PAGES */}
                              <ResMenuLi className="no-border">
                                {/* REMOVE HREF UNTILL WE LINK THIS */}
                                <ResMenuLink>
                                  <ResMenuProfile>
                                    <ProfileBtn type="button" className="gray">
                                      {imgSrc === null ? (
                                        <div>{userData.name === null ? "B" : userData?.name[0]}</div>
                                      ) : (
                                        <img src={imgSrc} alt={userData.name} onError={() => setImgSrc(null)} />
                                      )}
                                    </ProfileBtn>
                                    <span>{userData.name}</span>
                                  </ResMenuProfile>
                                  <ArrowDirectionWrapper>
                                    <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                  </ArrowDirectionWrapper>
                                </ResMenuLink>
                              </ResMenuLi>
                              {/* 
                               <ResMenuLi>
                                <ResMenuLink href="/account/notifications">
                                  {t("notifications")}
                                  <ResMenuIcon src="/images/icons/arrow-right-menu.svg" />
                                </ResMenuLink>
                              </ResMenuLi>
                              <ResMenuLi>
                                <ResMenuLink href="/about">
                                  {t("about")}
                                  <ResMenuIcon src="/images/icons/arrow-right-menu.svg" />
                                </ResMenuLink>
                              </ResMenuLi>
                              <ResMenuLi>
                                <ResMenuLink href="/explore">
                                  {t("explore")}
                                  <ResMenuIcon src="/images/icons/arrow-right-menu.svg" />
                                </ResMenuLink>
                              </ResMenuLi> */}
                              {(userData?.roles?.includes("instructor") ||
                                userData?.roles?.includes("pro_instructor")) && (
                                <>
                                  <ResMenuLi>
                                    <ResMenuLink href={`/${userData.username}`}>
                                      {t("profile")}
                                      <ArrowDirectionWrapper>
                                        <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                      </ArrowDirectionWrapper>
                                    </ResMenuLink>
                                  </ResMenuLi>
                                  {/* <ResMenuLi>
                                    <ResMenuLink href={`${process.env.NEXT_PUBLIC_URL_BAIMS}/instructors/bill`}>
                                      {t("receipts")}
                                      <ArrowDirectionWrapper>
                                        <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                      </ArrowDirectionWrapper>
                                    </ResMenuLink>
                                  </ResMenuLi> */}
                                </>
                              )}
                              {(userData?.roles?.includes("student") ||
                                userData?.roles.some((role) => role.includes("ambassador"))) && (
                                <ResMenuLi>
                                  <ResMenuLink href={`/profile`}>
                                    {t("profile")}
                                    <ArrowDirectionWrapper>
                                      <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                    </ArrowDirectionWrapper>
                                  </ResMenuLink>
                                  <ResMenuLink href={`/profile`}>
                                    {t("subscriptions")}
                                    <ArrowDirectionWrapper>
                                      <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                    </ArrowDirectionWrapper>
                                  </ResMenuLink>
                                </ResMenuLi>
                              )}
                              {/* {userData?.roles?.includes("ambassador-cash-reward") && (
                                <ResMenuLi>
                                  <ResMenuLink href={`${process.env.NEXT_PUBLIC_URL_BAIMS}/profile/ambassador`}>
                                    {t("baims-ambassador")}
                                    <ArrowDirectionWrapper>
                                      <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                    </ArrowDirectionWrapper>
                                  </ResMenuLink>
                                </ResMenuLi>
                              )} */}
                              {inHomeKSA && (
                                <ResMenuLi>
                                  <ResMenuLink
                                    as="a"
                                    href="https://safeer.baims.com/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    {t("become-ambassador")}
                                    <ArrowDirectionWrapper>
                                      <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                    </ArrowDirectionWrapper>
                                  </ResMenuLink>
                                </ResMenuLi>
                              )}
                              {/* Hide until confirm to release the ambassador page
                              {userData?.roles.some((role) => role.includes("ambassador")) && (
                                <ResMenuLi>
                                  <ResMenuLink href={"/profile/ambassador-performance"}>
                                    {tProfile("ambassador-performance")}
                                    <ArrowDirectionWrapper>
                                      <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                    </ArrowDirectionWrapper>
                                  </ResMenuLink>
                                </ResMenuLi>
                              )} */}
                              <ResMenuLi>
                                <ResMenuLink href={process.env.NEXT_PUBLIC_APP_AI_URL}>
                                  {t("baims-ai")}
                                  <ArrowDirectionWrapper>
                                    <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                  </ArrowDirectionWrapper>
                                </ResMenuLink>
                              </ResMenuLi>
                              {/* {userData?.roles?.includes("ambassador") && (
                                <>
                                  <ResMenuLi>
                                    <ResMenuLink href={`${process.env.NEXT_PUBLIC_URL_BAIMS}/profile/ambassador`}>
                                      {t("baims-ambassador")}
                                      <ArrowDirectionWrapper>
                                        <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                      </ArrowDirectionWrapper>
                                    </ResMenuLink>
                                  </ResMenuLi>
                                  <ResMenuLi>
                                    <ResMenuLink href={`${process.env.NEXT_PUBLIC_URL_BAIMS}/profile/leaderboard`}>
                                      {t("baims-leaderboard")}
                                      <ArrowDirectionWrapper>
                                        <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                      </ArrowDirectionWrapper>
                                    </ResMenuLink>
                                  </ResMenuLi>
                                </>
                              )} */}
                              <ResMenuLi>
                                <ResMenuLink as="button" type="button" onClick={() => logOut()}>
                                  {t("sign-out")}
                                  <ArrowDirectionWrapper>
                                    <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                  </ArrowDirectionWrapper>
                                </ResMenuLink>
                              </ResMenuLi>
                            </ResMenuUl>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <ResMenuTitle>{t("account")}</ResMenuTitle>
                            <ResMenuUl>
                              <ResMenuLi>
                                <ResMenuLink onClick={() => setLoginModal(true)}>
                                  {t("login")}
                                  <ArrowDirectionWrapper>
                                    <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                  </ArrowDirectionWrapper>
                                </ResMenuLink>
                              </ResMenuLi>
                              {inHomeKSA && (
                                <ResMenuLi>
                                  <ResMenuLink
                                    as="a"
                                    href="https://safeer.baims.com/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    {t("become-ambassador")}
                                    <ArrowDirectionWrapper>
                                      <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                    </ArrowDirectionWrapper>
                                  </ResMenuLink>
                                </ResMenuLi>
                              )}
                              <ResMenuLi>
                                <ResMenuLink href={process.env.NEXT_PUBLIC_APP_AI_URL}>
                                  {t("baims-ai")}
                                  <ArrowDirectionWrapper>
                                    <NavArrowRightSvgIcon color="#1a1a1a" width="12" height="10" />
                                  </ArrowDirectionWrapper>
                                </ResMenuLink>
                              </ResMenuLi>
                              {/* HIDE ABOUT/EXPLORE */}
                              {/* <ResMenuLi>
                                <ResMenuLink href="/login">
                                  {t("sign-up")}
                                  <ResMenuIcon src="/images/icons/arrow-right-menu.svg" />
                                </ResMenuLink> 
                              </ResMenuLi> */}
                            </ResMenuUl>
                            {/* <ResMenuTitle>{t("main-menu")}</ResMenuTitle> 
                            <ResMenuUl>
                              <ResMenuLi>
                                <ResMenuLink href="/explore">
                                  {t("explore")}
                                  <ResMenuIcon src="/images/icons/arrow-right-menu.svg" />
                                </ResMenuLink>
                              </ResMenuLi>
                              <ResMenuLi>
                                <ResMenuLink href="/about">
                                  {t("about")}
                                  <ResMenuIcon src="/images/icons/arrow-right-menu.svg" />
                                </ResMenuLink>
                               </ResMenuLi>
                            </ResMenuUl> */}
                          </Fragment>
                        )}
                        {isMobile && (
                          <div>
                            <ResMenuTitle>{t("the-language")}</ResMenuTitle>
                            <NavbarLangSwitch setShowBlurredBg={setShowBlurredBg} navbarType={navbarType} />
                          </div>
                        )}
                        <ResMenuTitle>{t("get-the-app")}</ResMenuTitle>
                        <AppsWrapper>
                          <DownloadAppLink href={APPLE_APPSTORE_LINK} target="_blank" rel="noreferrer noopener">
                            <AppStoreSvgImg width="145" bgColor={AppsIconBgColor(isFoundingDay)} />
                          </DownloadAppLink>
                          <DownloadAppLink href={GOOGLE_PLAY_LINK} target="_blank" rel="noreferrer noopener">
                            <GooglePlaySvgImg width="145" bgColor={AppsIconBgColor(isFoundingDay)} />
                          </DownloadAppLink>
                        </AppsWrapper>
                      </ResMenuWrapper>
                    </ResMenuContainer>
                  )}
                </HeaderInner>
              </Col>
            )}
          </Row>
        </Container>
      </Header>
    </NavDropdownProvider>
  );
}
