import { HeaderInner, Separator } from "@Components/Navbar/Navbar.styled";
import { FOUNDING_DAY_THEME, KSA_COUNTRY_CODE, NATIONAL_DAY_THEME } from "@Constants/index";
import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import { mq } from "@Utils/StyleUtil";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import BaimsLogoSvg from "public/svgs/logos/BaimsLogoSvg";
import BoubyanBankLogoSvg from "public/svgs/logos/BoubyanBankLogoSvg";
import KsaNationalDayLogo from "public/svgs/logos/KsaNationalDayLogo";
import { Fragment, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { APIResponse, Meta } from "types/ApiResponse";
import { BreadCrumbs } from "types/BreadCrumbs";
import { NavbarVariant } from "types/common";
import * as RamdanLottie from "../public/lotties/ramadan.json";
import * as RamdanLottieWhite from "../public/lotties/ramadan-white.json";
const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

type Props = {
  navbarType: NavbarVariant;
  breadCrumbHirarchy?: APIResponse<BreadCrumbs[], Meta>;
};

const StyledLogo = styled.img`
  width: 118px;
  height: 52px;
  object-fit: contain;
  ${mq(1024)} {
    width: 100px;
    height: 44px;
  }
`;

const LottieStyle = styled.div<{ readonly navbarType: NavbarVariant }>`
  ${({ navbarType }) =>
    navbarType === "white-bg" &&
    css`
      path {
        fill: #000;
        stroke: #000;
      }
    `}
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mq(1024)} {
    ${Separator} {
      display: none;
    }
  }
`;

export default function Logo({ navbarType, breadCrumbHirarchy }: Props) {
  const { locale } = useRouter();
  const { t } = useTranslation("common");
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  const [isKwHg, setIsKwHg] = useState(false);
  const isKSA = localIpInfo?.country_code === KSA_COUNTRY_CODE;

  const isKSAFoundingDay2025 = (): boolean => {
    const currentDate = new Date();
    const startDate = new Date("2025-02-01");
    const endDate = new Date("2025-02-28");
    return false || (localIpInfo?.country_code || "").toLocaleLowerCase() === "sa";
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    // animationData: locale === "ar" ? KSALogoAr : KSALogoEn,
    animationData: navbarType === "white-bg" ? RamdanLottie : RamdanLottieWhite,
  };

  useEffect(() => {
    const falsyArr = [];
    breadCrumbHirarchy?.data?.forEach((u) => falsyArr.push(u.id === 3));
    falsyArr.includes(true) ? setIsKwHg(true) : setIsKwHg(false);
  }, [breadCrumbHirarchy]);

  return (isKSA && themeType === FOUNDING_DAY_THEME) || isKSAFoundingDay2025() ? (
    <LottieStyle navbarType={navbarType}>
      <Lottie options={defaultOptions} width={120} />
    </LottieStyle>
  ) : isKSA && themeType === NATIONAL_DAY_THEME ? (
    <Fragment>
      {navbarType === "white-bg" ? (
        <LogoWrapper>
          <BaimsLogoSvg color="#000000" />
          <Separator />
          <KsaNationalDayLogo />
        </LogoWrapper>
      ) : (
        <LogoWrapper>
          <BaimsLogoSvg color="#ffffff" />
          <Separator />
          <KsaNationalDayLogo white />
        </LogoWrapper>
      )}
    </Fragment>
  ) : (
    <HeaderInner>
      {/* {navbarType === "white-bg" ? <BaimsLogoSvg color="#000000" /> : <BaimsLogoSvg color="#ffffff" />} */}
      <Lottie options={defaultOptions} width={120} />
      {isKwHg && (
        <>
          <Separator isMobile /> <BoubyanBankLogoSvg />
        </>
      )}
    </HeaderInner>
  );
}
