// @ts-nocheck
/* eslint-disable */
import { CommonButton } from "@Components/common/CommonButton";
import { useAuth } from "@Context/Auth.context";
import { NavDropdownContext } from "@Context/NavDropdown.context";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import SupportSvgIcon from "public/svgs/icons/global-icons/SupportSvgIcon";
import { Fragment, ReactElement, useContext } from "react";
import { Configure, Hits, Index, useInstantSearch } from "react-instantsearch";
import { SearchResults } from "react-instantsearch-core";
import { AlgoliaInstructor, AlgoliaLiveSession, AlgoliaPackages, AlgoliaSubject } from "types/AlgoliaSearch";
import { EmptySearchDesc, SearchResultsTitle, SeeAllResults } from "../Navbar.styled";
import SearchResultCard from "./SearchResultCard";

type Results = {
  subjects: SearchResults<AlgoliaSubject>;
  instructors: SearchResults<AlgoliaInstructor>;
  "live-sessions": SearchResults<AlgoliaLiveSession>;
  packages: SearchResults<AlgoliaPackages>;
};

function NoResultsBoundary({ children, fallback }) {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

export default function NavbarSearchResults({ searchState }): ReactElement {
  const { t } = useTranslation("common");
  const { t: tLanding } = useTranslation("Landing");
  const { renderState } = useInstantSearch();
  const { localIpInfo } = useAuth();
  const { setDropDownMenu } = useContext(NavDropdownContext);
  const { push } = useRouter();

  const hasResults = (indexName) => {
    if (renderState && renderState[indexName] && renderState[indexName]?.hits?.hits.length) {
      return renderState[indexName].hits.hits;
    } else {
      return null;
    }
  };

  function Hit({ hit, type }) {
    return <SearchResultCard key={hit.id} searchState={searchState} data={hit} type={type} />;
  }

  return (
    <Fragment>
      {searchState.length >= 4 &&
        !hasResults("subjects") &&
        !hasResults("instructors") &&
        !hasResults("packages") &&
        !hasResults("live-sessions") && (
          <div
            style={{
              padding: "3px 0",
            }}
          >
            <SearchResultsTitle align="center">
              {t("no_result_round_for", {
                count: searchState?.length > 10 ? searchState?.slice(0, 10) + "..." : searchState,
              })}
            </SearchResultsTitle>
            <EmptySearchDesc>{t("contact_support_message")}</EmptySearchDesc>
            <CommonButton
              className="open_intercom"
              onClick={() => {
                if (window?.Intercom) {
                  window?.Intercom("show");
                }
                setDropDownMenu(false);
              }}
              variant="fullWidth"
            >
              <SupportSvgIcon margin="0 12px" />
              {t("contact_support")}
            </CommonButton>
          </div>
        )}

      {searchState.length >= 4 && (
        <div>
          <div
            style={{
              padding: "12px 0",
            }}
          >
            <Index indexName="subjects">
              <Configure hitsPerPage={1} analytics={true} filters={`breadcrumbs:'${localIpInfo?.emojie}'`} />
              <Fragment>
                {hasResults("subjects") && <SearchResultsTitle>{tLanding("subjects")}</SearchResultsTitle>}
                <NoResultsBoundary fallback={<></>}>
                  <Hits
                    hitComponent={({ hit }) =>
                      Hit({
                        hit,
                        type: "subject",
                      })
                    }
                  />
                </NoResultsBoundary>
              </Fragment>
            </Index>
            <Index indexName="packages">
              <Configure hitsPerPage={1} analytics={true} filters={`breadcrumbs:'${localIpInfo?.emojie}'`} />
              <Fragment>
                {hasResults("packages") && <SearchResultsTitle>{tLanding("Packages_upperCase")}</SearchResultsTitle>}
                <NoResultsBoundary fallback={<></>}>
                  <Hits
                    hitComponent={({ hit }) =>
                      Hit({
                        hit,
                        type: "packages",
                      })
                    }
                  />
                </NoResultsBoundary>
              </Fragment>
            </Index>
            <Index indexName="live-sessions">
              <Configure hitsPerPage={1} analytics={true} filters={`country:'${localIpInfo?.country_code}'`} />
              <Fragment>
                {hasResults("live-sessions") && <SearchResultsTitle>{tLanding("live-sessions")}</SearchResultsTitle>}
                <NoResultsBoundary fallback={<></>}>
                  <Hits
                    hitComponent={({ hit }) =>
                      Hit({
                        hit,
                        type: "live",
                      })
                    }
                  />
                </NoResultsBoundary>
              </Fragment>
            </Index>
            <Index indexName="instructors" className="">
              <Configure hitsPerPage={1} />
              <Fragment>
                {hasResults("instructors") && <SearchResultsTitle>{tLanding("instructors")}</SearchResultsTitle>}
                <NoResultsBoundary fallback={<></>}>
                  <Hits
                    hitComponent={({ hit }) =>
                      Hit({
                        hit,
                        type: "instructor",
                      })
                    }
                  />
                </NoResultsBoundary>
              </Fragment>
            </Index>

            {(hasResults("subjects") ||
              hasResults("instructors") ||
              hasResults("packages") ||
              hasResults("live-sessions")) && (
              <SeeAllResults
                onClick={() => {
                  push(`/search?query=${searchState}`);
                  setDropDownMenu(false);
                }}
              >
                {tLanding("Show_all_results")}
              </SeeAllResults>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}
